@font-face {
  font-family: fe-font;
  src: url("assets/font/FE-FONT.TTF") format("opentype");
}

$dark-primary-text: #212121;
$light-primary-text: #e7e1e1;
//$light-primary-text: #cbb8b8;

$personal-primary: (
  50: #fdf6ed,
  75:  #f7dbb9,
  100: #ecc18c,
  200: #ecaf65,
  300: #eb9834,
  400: #f0901b,
  500: #f18b0f,
  550: #f18b0f50,
  600: #b96806,
  700: #965506,
  800: #965506,
  900: #965506,
  950: #96550680,
  A100: #ecc18c,
  A200: #ecaf65,
  A400: #eb9834,
  A700: #f0901b,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $dark-primary-text,
    600: $dark-primary-text,
    700: $dark-primary-text,
    800: $dark-primary-text,
    900: $dark-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $dark-primary-text,
  )
);


$personal-accent: (
  50: #EDEEEF4,
  100: #EDEEEF4,
  200: #EDEEEF4,
  300: #EDEEEF4,
  400: #EDEEEF4,
  500: #EDEEEF4,
  600: #EDEEEF4,
  700: #EDEEEF4,
  800: #EDEEEF4,
  900: #EDEEEF4,
  A100: #EDEEEF,
  A200: #EDEEEF,
  A400: #EDEEEF,
  A700: #EDEEEF,
  contrast: (
    50:  $light-primary-text,
    100: $light-primary-text,
    200: $light-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $light-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  )
);

$accent: (
  50:  #EDEEEF,
  100: #D3D6D8,
  200: #B6BABE,
  300: #989EA4,
  400: #828A91,
  500: #6C757D,
  600: #646D75,
  700: #59626A,
  800: #4F5860,
  900: #3D454D,
  A100: #A9D3FD,
  A200: #77B9FC,
  A400: #41A0FF,
  A700: #2793FF,
  contrast: (
    50:  $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $light-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  )
);

$personal-warn: (
  50: #F5E7E850,
  100: #F5E7E8,
  200: #E7C2C5,
  300: #D7999F,
  400: #C67079,
  500: #A72E39,
  600: #AE333F,
  700: #A72E39,
  800: #9D2731,
  900: #942029,
  950: #942029,
  A100: #A72E39,
  A200: #9D2731,
  A400: #942029,
  A700: #942029,
  contrast: (
    50:  $light-primary-text,
    100: $light-primary-text,
    200: $light-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $light-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  )
);

$accent: (
  100: #DEDACB,
  200: #C1BBA6,
);


$attention: (
  100:#f18b0f,
  120:#f18b0f20,
  200:#F8E32D,
  300:#EB4343,
  400:#19a3ce,
);

$status: (
  1:#19A3CE,
  2:#7EA131,
  3:#BA1717,
  4:#a18131,
);


.def {
  ::ng-deep .mat-icon {
    margin: 5px 0!important;
    width: 100%!important;
    display: flex!important;
    align-items: center!important;
    justify-content: center!important;
    //color: map-get($personal-accent,500)!important;

  }
}
.main{
  font-weight: 600;
  color: map-get($personal-primary,200);
}
button.def{
  border: 2px solid map-get($personal-warn,900);
}
.border{
  border: 2px solid map-get($personal-primary,400);
}
.primary{
  background: map-get($personal-primary,400)!important;
  color: map-get($personal-accent,500)!important;;
}
.primary-list {
  background: transparent;
  color: map-get($personal-accent,500)!important;;
}
.warn{
  background: map-get($personal-warn,400)!important;
  color: map-get($personal-primary,500)!important;;
}
.accent{
  background: map-get($personal-primary,700)!important;
}

.mat-datepicker-content-container{
  background: map-get($personal-primary,500)!important;
}


::ng-deep .mat-form-field-label {
  color: map-get($personal-accent,700)!important;
}

::ng-deep .mat-form-field-underline {
  background-color: map-get($personal-accent,700);
}

::ng-deep .mat-form-field-ripple {
  background-color: map-get($personal-accent,700);
}

::ng-deep .mat-form-field-label {
  color: map-get($personal-accent,700);
}

::ng-deep .mat-form-field-flex{
  ::ng-deep .mat-button-wrapper{
    color: map-get($personal-accent,700);
  }
}
input {
  caret-color: map-get($personal-accent,700)!important;
}
textarea{
  caret-color: map-get($personal-accent,700)!important;
  color: map-get($personal-accent,600)!important;
}

input:focus {
  background: none;
}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  -webkit-text-fill-color: map-get($personal-accent, 700);
  -webkit-box-shadow: 0 0 0px 1000px map-get($personal-primary, 900) inset;}

::ng-deep .mat-paginator .mat-icon-button{
  background-color: unset!important;
  color: map-get($personal-warn, 700)!important;
  fill: map-get($personal-warn, 700)!important;
}

.btn{
  border-radius: 6px;
  padding: 3px 6px;
  margin: 0;
  //border: 2px solid #828A91;
  color: #828A91;
}

.btn:active{
  background: #828A9160;
}




