// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
@use './colors' as col;

// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$open-delivery-primary: mat.define-palette(col.$personal-primary);
$open-delivery-accent: mat.define-palette(col.$personal-accent, A200, A100, A400);

// The warn palette is optional (defaults to red).
$open-delivery-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$open-delivery-theme: mat.define-light-theme((
  color: (
    primary: $open-delivery-primary,
    accent: $open-delivery-accent,
    warn: $open-delivery-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($open-delivery-theme);

/* You can add global styles to this file, and also import other style files */

body {
  width: 100vw;
  background: #00000020
}

.container {
  max-width: none;
  color: #6C757D;

}

.btn-primary {
  background: map-get(col.$personal-primary, 500) !important;
  color: #FFFFFF!important;
  border: 2px solid map-get(col.$personal-primary, 500) !important;
}

.btn-primary:hover, .btn-primary:active, .btn-primary:visited {
  background: map-get(col.$personal-primary, 700) !important;
  color: #FFFFFF;
  border: 2px solid map-get(col.$personal-primary, 500) !important;
}

.btn-primary-outline {
  background: transparent !important;
  color: map-get(col.$personal-primary, 500) !important;
  border: 2px solid map-get(col.$personal-primary, 500) !important;
}

.btn-primary-outline:hover, .btn-primary-outline:active, .btn-primary-outline:visited {
  background: map-get(col.$personal-accent, 100) !important;
  color: map-get(col.$personal-primary, 500) !important;
  border: 2px solid map-get(col.$personal-primary, 500) !important;
}

.field {
  label {
    //color: map-get(col.$personal-primary, 500) !important;
  }

  .form-control {
    border: 2px solid map-get(col.$personal-accent, 400) !important;
    background: transparent;
  }
}

.form-control:disabled {
  border: 2px solid map-get(col.$personal-accent, 400) !important;
  opacity: .7;
  background: map-get(col.$personal-accent, 100) !important;
}
.red{
  color: map-get(col.$attention, 300) !important;
}


html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-mdc-snack-bar-container .mdc-snackbar__surface {
  background-color: #FFFFFF!important;

  .mat-mdc-snack-bar-label, .mdc-snackbar__label{
    color: map-get(col.$personal-primary, 600) !important;;
  }
  .mat-mdc-button-touch-target {
    color: map-get(col.$attention, 300) !important;
  }
}

.field {
  color: map-get(col.$personal-primary, 500);

  input {
    background: transparent;
    color: map-get(col.$personal-accent, 500);
    border: 1px solid #6C757D;
  }

  input:focus {
    background: transparent;
    color: map-get(col.$personal-accent, 500);
    border: 1px solid map-get(col.$personal-accent, 500);
  }
  input:disabled {
    background: #3b3b3b30;
    color: map-get(col.$personal-accent, 500);
    border: 1px solid map-get(col.$personal-accent, 500);
    opacity: .5;

  }

  span {
    color: map-get(col.$personal-accent, 500);
    border: 1px solid map-get(col.$personal-accent, 500);
    background: map-get(col.$personal-accent, 100);

  }

  button {
    background: #6C757D15;
  }

  a {
    color: map-get(col.$personal-accent, 500);

  }
  button:disabled {
    background: #3b3b3b30;
    opacity: .5;
    color: map-get(col.$personal-accent, 500);
    border: 1px solid map-get(col.$personal-accent, 500)!important;
  }
}

.field-dropdown{
  background: transparent;
  color: map-get(col.$personal-accent, 500);
  border: 1px solid map-get(col.$personal-accent, 500)!important;
}
.field-dropdown:disabled{
  opacity: .5;
  background: #00000010;
  color: map-get(col.$personal-accent, 500);
  border: 1px solid rgba(0, 0, 0, 0.38)!important;
}
.btn-primary{
  color: #fff;
  background:  map-get(col.$personal-primary, 500);;
}



.chip-gray {
  color: #6C757D;
  background: #6C757D40;
  border: 2px solid #6C757D;
  border-radius: 6px;
  padding: 3px 6px;
  display: flex;
  align-items: center;
  width: fit-content;
  justify-content: center;
  max-height: 32px;
}

.chip-red {
  color: map-get(col.$attention, 300);
  background: #EB434340;
  border: 2px solid map-get(col.$attention, 300);
  border-radius: 6px;
  padding: 3px 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 32px;

}

.chip-yellow {
  color: map-get(col.$status, 4);
  background: #F8E32D40;
  border: 2px solid map-get(col.$status, 4);
  border-radius: 6px;
  padding: 3px 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 32px;

}

.chip-blue {
  color: map-get(col.$status, 1);
  background: #19A3CE40;
  border: 2px solid map-get(col.$status, 1);
  border-radius: 6px;
  padding: 3px 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 32px;

}